import { gql } from "@apollo/client";

export const CREATE_CATEGORY = gql`
  mutation CreateCategory($title: String!) {
    createCategory(title: $title) {
      uuid
      title
      items {
        uuid
        name
        order
        description
        price {
          amount
          currency
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const CHANGE_CATEGORY_TITLE = gql`
  mutation ChangeCategoryTitle($uuid: String!, $title: String!) {
    changeCategoryTitle(uuid: $uuid, title: $title) {
      uuid
      title
      items {
        uuid
        name
        order
        description
        price {
          amount
          currency
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const REMOVE_CATEGORY = gql`
  mutation RemoveCategory($uuid: String!) {
    removeCategory(uuid: $uuid)
  }
`;

export const CREATE_MENU_ITEM = gql`
  mutation CreateMenuItem(
    $categoryUUID: String!
    $name: String!
    $order: Int!
    $price: Float!
    $currency: String!
    $description: String!
  ) {
    createMenuItem(
      categoryUUID: $categoryUUID
      item: {
        name: $name
        order: $order
        price: $price
        currency: $currency
        description: $description
      }
    ) {
      uuid
      name
      order
      description
      price {
        amount
        currency
      }
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_MENU_ITEM = gql`
  mutation UpdateMenuItem(
    $uuid: String!
    $name: String!
    $order: Int!
    $price: Float!
    $currency: String!
    $description: String!
  ) {
    updateMenuItem(
      uuid: $uuid
      item: {
        name: $name
        order: $order
        price: $price
        currency: $currency
        description: $description
      }
    ) {
      uuid
      name
      order
      description
      price {
        amount
        currency
      }
      createdAt
      updatedAt
    }
  }
`;

export const REMOVE_MENU_ITEM = gql`
  mutation RemoveMenuItem($uuid: String!) {
    removeMenuItem(uuid: $uuid)
  }
`;

export const UPDATE_GALLERY_IMAGE_ORDER = gql`
  mutation UpdateGalleryImageOrder($imageUUID: String!, $order: Int!) {
    updateGalleryImageOrder(imageUUID: $imageUUID, order: $order)
  }
`;

export const SET_RESTAURANT_PRIMARY_IMAGE = gql`
  mutation SetRestaurantPrimaryImage(
    $data: String!
    $alt: String!
    $type: String!
  ) {
    setRestaurantPrimaryImage(image: { data: $data, alt: $alt, type: $type }) {
      uuid
      data
      type
      alt
      createdAt
      updatedAt
    }
  }
`;

export const ADD_IMAGE_TO_GALLERY = gql`
  mutation AddImageToGallery($data: String!, $alt: String!, $type: String!) {
    addImageToGallery(image: { data: $data, alt: $alt, type: $type }) {
      uuid
      data
      type
      alt
      createdAt
      updatedAt
    }
  }
`;

export const REMOVE_IMAGE_FROM_GALLERY = gql`
  mutation RemoveImage($uuid: String!) {
    removeImage(uuid: $uuid)
  }
`;

export const UPDATE_IMAGE_IN_GALLERY = gql`
  mutation UpdateImage(
    $uuid: String!
    $data: String!
    $alt: String!
    $type: String!
  ) {
    updateImage(image: { uuid: $uuid, data: $data, type: $type, alt: $alt }) {
      uuid
      data
      type
      alt
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_RESTAURANT_INFORMATION = gql`
  mutation updateRestaurantInformation(
    $arrivalPolicy: String!
    $cancellationPolicy: String!
    $cityName: String!
    $cityLocation: String!
    $country: String!
    $contactEmail: String!
    $contactName: String!
    $contactPhone: String!
    $cuisine: [String]!
    $instagram: String
    $description: String!
    $generalPolicy: String!
    $locationLatitude: Float!
    $locationLongitude: Float!
    $name: String!
    $notes: String!
    $seatCount: Int!
    $website: String!
  ) {
    updateRestaurantInformation(
      properties: {
        cityName: $cityName
        cityLocation: $cityLocation
        country: $country
        arrivalPolicy: $arrivalPolicy
        cancellationPolicy: $cancellationPolicy
        contactEmail: $contactEmail
        contactName: $contactName
        contactPhone: $contactPhone
        cuisine: $cuisine
        description: $description
        generalPolicy: $generalPolicy
        locationLatitude: $locationLatitude
        locationLongitude: $locationLongitude
        name: $name
        instagram: $instagram
        notes: $notes
        seatCount: $seatCount
        website: $website
      }
    ) {
      uuid
      name
      notes
      seatCount
      details {
        arrivalPolicy
        description
        generalPolicy
        cancellationPolicy
      }
      city {
        name
        location
        country
      }
      cuisine
      contact {
        name
        email
        phone
        website
        instagram
      }
      location {
        longitude
        latitude
      }
    }
  }
`;

export const SET_RESY_TAGS = gql`
  mutation SetResyTags($tags: [String]!) {
    setResyTags(tags: $tags) {
      name
    }
  }
`;

export const ACTIVATE_RESY = gql`
  mutation ActivateResy($active: Boolean!) {
    activateResy(active: $active)
  }
`;

export const SET_RESY_VENUE = gql`
  mutation SetResyVenue($id: String!) {
    setResyVenue(id: $id) {
      id
      name
    }
  }
`;

export const SET_RESY_CREDENTIALS = gql`
  mutation SetResyCredentials($email: String!, $password: String!) {
    setResyCredentials(email: $email, password: $password)
  }
`;
