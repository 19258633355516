import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import NoData from "../components/common/NoData";
import logoLight from "../../assets/images/logo-light.svg";
import logoDark from "../../assets/images/logo-dark.svg";
import { UIMode } from "../constants";
import { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const { t } = useTranslation();
  const { uiMode } = useContext(AppContext);
  const navigate = useNavigate();

  const logo = uiMode === UIMode.Dark ? logoDark : logoLight;
  return (
    <Container className="text-center pt-[10%]">
      <img src={logo} alt="Logo" className="w-28 mx-auto" />
      <NoData
        title={t("notFound.title")}
        message={t("notFound.message")}
        topMargin="0"
        action={() => navigate("/")}
        actionTitle={t("notFound.goHome")}
      />
    </Container>
  );
}
