import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Backdrop,
  Box,
  Button,
  Fade,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { modalStyle } from "../../constants";

export default function NewMenuModal({
  save,
  isOpen,
  handleClose,
  defaultName = "",
  editCategoryUuid = "",
}: {
  save: (name: string, editCategoryUuid?: string) => Promise<unknown>;
  isOpen: boolean;
  handleClose: () => void;
  defaultName?: string;
  editCategoryUuid?: string;
}) {
  const [name, setName] = useState(defaultName);
  const { t } = useTranslation();
  const handleSave = async () => {
    const error = await save(name!, editCategoryUuid);
    if (!error) setName("");
  };

  useEffect(() => {
    if (defaultName !== name) setName(defaultName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultName]);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="menu-modal-title"
      aria-describedby="menu-modal-description"
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={isOpen}>
        <Box sx={modalStyle}>
          <div className="flex justify-end">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography id="menu-modal-title" variant="h6">
            {t("menu.addMenu")}
          </Typography>
          <Typography id="menu-modal-description" variant="body2">
            {t("menu.addMenuDescription")}
          </Typography>
          <TextField
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            label={t("common.name")}
            required={true}
            variant="outlined"
            fullWidth
            placeholder={t("menu.menuNamePlaceholder")}
            margin="normal"
            sx={{ my: 3 }}
          />
          <div className="flex justify-end gap-2">
            <Button onClick={handleClose}>{t("common.cancel")}</Button>
            <Button
              variant="contained"
              type="button"
              color="primary"
              disabled={!name}
              onClick={handleSave}
            >
              {t("common.save")}
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
