import {
  Button,
  IconButton,
  InputBase,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { Add, Delete, DragHandle as DragIcon } from "@mui/icons-material";
import { arrayMoveImmutable } from "array-move";

import { useTranslation } from "react-i18next";

import {
  MenuCategory,
  MenuItem,
  MenuItemKey,
  NewMenuItemProps,
  TableHeader,
} from "@/app/types";
import { CurrencySymbol } from "../../constants";

interface SortableItemParams {
  itemIndex: number;
  row: MenuItem;
  tableHeaders: TableHeader[];
  handleUpdateMenuContent: (menuItemProps: NewMenuItemProps | null) => void;
  tab: number;
  categories: MenuCategory[];
  inputRef: React.RefObject<HTMLInputElement>;
  handleRemoveMenuItem: (tab: number, index: number) => void;
  isLoading: boolean;
}

interface SortableListParams {
  categories: MenuCategory[];
  tab: number;
  isLoading: boolean;
  handleRemoveMenuItem: (tab: number, index: number) => void;
  handleUpdateMenuContent: (menuItemProps: NewMenuItemProps | null) => void;
  addMenuItem: (tab: number) => void;
  inputRef: React.RefObject<HTMLInputElement>;
  tableHeaders: TableHeader[];
}

const SortableList = SortableContainer<SortableListParams>(
  ({
    categories,
    tab,
    isLoading,
    handleRemoveMenuItem,
    handleUpdateMenuContent,
    addMenuItem,
    inputRef,
    tableHeaders,
  }: SortableListParams) => {
    const { t } = useTranslation();
    return (
      <TableBody>
        {categories[tab].items.map((row, index) => (
          <SortableItem
            key={index}
            index={index}
            row={row}
            tab={tab}
            itemIndex={index}
            categories={categories}
            isLoading={isLoading}
            handleRemoveMenuItem={handleRemoveMenuItem}
            handleUpdateMenuContent={handleUpdateMenuContent}
            inputRef={inputRef}
            tableHeaders={tableHeaders}
          />
        ))}
        <TableRow>
          <TableCell colSpan={5}>
            <Button
              startIcon={<Add />}
              onClick={() => addMenuItem(tab)}
              disabled={isLoading}
              type="button"
              color="secondary"
            >
              {t("menu.addMenuItem")}
            </Button>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  },
);

const DragHandle = SortableHandle(() => (
  <IconButton sx={{ cursor: "move" }}>
    <DragIcon />
  </IconButton>
));

const SortableItem = SortableElement<SortableItemParams>(
  ({
    itemIndex,
    row,
    tableHeaders,
    handleUpdateMenuContent,
    tab,
    categories,
    inputRef,
    handleRemoveMenuItem,
    isLoading,
  }: SortableItemParams) => (
    <MenuItemRow
      key={itemIndex}
      row={row}
      tab={tab}
      itemIndex={itemIndex}
      categories={categories}
      isLoading={isLoading}
      handleRemoveMenuItem={handleRemoveMenuItem}
      handleUpdateMenuContent={handleUpdateMenuContent}
      inputRef={inputRef}
      tableHeaders={tableHeaders}
    />
  ),
);

const MenuItemRow = ({
  itemIndex,
  row,
  tableHeaders,
  handleUpdateMenuContent,
  tab,
  categories,
  inputRef,
  handleRemoveMenuItem,
  isLoading,
}: SortableItemParams) => {
  const { t } = useTranslation();
  return (
    <TableRow
      key={itemIndex}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell title={t("common.move")}>
        <DragHandle />
      </TableCell>
      {tableHeaders.map((header) => (
        <TableCell key={`${header.key}-${itemIndex}-cell`} scope="row">
          <div className="flex items-center gap-1">
            <Typography variant="subtitle1">
              {header.key === "price" ? CurrencySymbol[row.price.currency] : ""}
            </Typography>
            <InputBase
              id={`${header.key}-${itemIndex}-input`}
              margin="none"
              type={["price", "order"].includes(header.key) ? "number" : "text"}
              value={
                header.key === "price" ? row.price.amount : row[header.key]
              }
              sx={{ p: 0, m: 0 }}
              fullWidth={true}
              placeholder={t(`menu.${header.key}Placeholder`)}
              disabled={header.key === "uuid"}
              ref={
                itemIndex + 1 === categories[tab]?.items?.length &&
                header.key === "name"
                  ? inputRef
                  : null
              }
              onChange={(e) =>
                handleUpdateMenuContent({
                  targetValue: e.target.value,
                  categoryIndex: tab,
                  index: itemIndex,
                  key: header.key as MenuItemKey,
                })
              }
            />
          </div>
        </TableCell>
      ))}
      <TableCell>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            handleRemoveMenuItem(tab, itemIndex);
          }}
          disabled={isLoading}
          aria-label={t("common.delete")}
        >
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default function MenuTable({
  categories,
  tab,
  isLoading,
  handleRemoveMenuItem,
  handleUpdateMenuContent,
  addMenuItem,
  inputRef,
  tableHeaders,
  setOrder,
}: SortableListParams & {
  setOrder?: (tab: number, items: MenuItem[]) => void;
}) {
  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    const items = arrayMoveImmutable(
      categories?.[tab].items ?? [],
      oldIndex,
      newIndex,
    ).map((item, index) => ({
      ...item,
      order: index,
    }));

    setOrder?.(tab, items);
  };

  return (
    <SortableList
      categories={categories}
      tab={tab}
      isLoading={isLoading}
      handleRemoveMenuItem={handleRemoveMenuItem}
      handleUpdateMenuContent={handleUpdateMenuContent}
      addMenuItem={addMenuItem}
      inputRef={inputRef}
      axis="y"
      useDragHandle
      distance={4}
      onSortEnd={onSortEnd}
      tableHeaders={tableHeaders}
    />
  );
}
