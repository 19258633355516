import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Box, Container, Typography } from "@mui/material";
import { getRestaurantRoutePrefix } from "../../utils/getRestaurantRoutePrefix.util";
import { West } from "@mui/icons-material";

const menuItems = [
  {
    title: "account.profileTitle",
    to: getRestaurantRoutePrefix(null, ""),
  },
  {
    title: "account.menuTitle",
    to: getRestaurantRoutePrefix(null, "menu"),
  },
];

export default function AccountHeader() {
  const { t } = useTranslation();
  const { pathname } = window.location;

  return (
    <div className="mb-10">
      <Container maxWidth="xl">
        <Box className="flex gap-2 my-4">
          {menuItems.map((item, index) => (
            <Link key={index} to={item.to}>
              <Typography
                variant="body1"
                fontWeight={pathname.startsWith(item.to) ? 700 : 300}
              >
                {t(item.title)}
              </Typography>
            </Link>
          ))}
        </Box>
        {!menuItems.some((i) => i.to === pathname) && (
          <Link
            to={getRestaurantRoutePrefix(null, "")}
            aria-label={t("account.profileTitle")}
          >
            <West />
          </Link>
        )}
      </Container>
      {/* <Notifications restaurant={restaurant} /> */}
    </div>
  );
}
