import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "../../pages/home/Home";
import Reservations from "../../pages/reservations/Reservations";
import Login from "../../pages/auth/Login";
import NotFound from "../../pages/NotFound";
import Profile from "../../pages/account/Profile";
import PhotoGallery from "../../pages/account/PhotoGallery";
import MenuPage from "../../pages/account/MenuPage";
import ProfileEdit from "../../pages/account/ProfileEdit";
import ProtectedRoute from "./ProtectedRoute";
import NavigationBar from "./NavigationBar";
import ForgotPassword from "../../pages/auth/ForgotPassword";
import PlatformPage from "../../pages/account/PlatformPage";

const routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/reservations",
    element: <Reservations />,
  },
  {
    path: "/account/profile",
    element: <Profile />,
  },
  {
    path: "/account/profile/menu",
    element: <MenuPage />,
  },
  {
    path: "/account/profile/photo-gallery",
    element: <PhotoGallery />,
  },
  {
    path: "/account/profile/edit",
    element: <ProfileEdit />,
  },
  {
    path: "/account/profile/connect-platform/:platform",
    element: <PlatformPage />,
  },
];
export function ApplicationRouter() {
  return (
    <BrowserRouter>
      <NavigationBar />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={<ProtectedRoute children={route.element} />}
          />
        ))}

        <Route element={<NotFound />} path="*" />
      </Routes>
    </BrowserRouter>
  );
}
