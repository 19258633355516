import React from "react";
import { ApolloProvider } from "@apollo/client";
import ReactDOM from "react-dom/client";
import "moment/locale/en-gb";
import "moment/locale/de";

import "./assets/fonts/kessler/stylesheet.css";
import "./assets/fonts/enduro/stylesheet.css";

import "./index.css";
import "./setupTranslations";
import reportWebVitals from "./reportWebVitals";
import { client } from "./app/graphql";

import AppContainer from "./app/components/shared/AppContainer";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <AppContainer />
    </ApolloProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
