import { isManagement } from "../config";
import { Restaurant } from "../types";

export const getRestaurantRoutePrefix = (
  restaurant?: Restaurant | null,
  path?: string,
) => {
  return isManagement
    ? `/restaurants/${restaurant?.uuid}/${path}`
    : `/account/profile/${path}`;
};
