import { Navigate } from "react-router-dom";

import { LocalStorageKeys } from "../../constants";

export default function ProtectedRoute({
  children,
}: {
  children: React.ReactNode;
}) {
  if (!window.localStorage.getItem(LocalStorageKeys.Token)) {
    return <Navigate to="/login" replace />;
  }

  return <>{children}</>;
}
