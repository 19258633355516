import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
} from "@apollo/client";
import { DomainEnv } from "../config";
import { LocalStorageKeys } from "../constants";

import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { logout } from "../../keycloak";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError) {
    const { statusCode } = networkError as any;
    switch (statusCode) {
      case 401:
        logout();
        break;
    }
  }
});

const httpLink = createHttpLink({
  uri: `https://backend.${DomainEnv[process.env.NODE_ENV]}.accessonboard.com/webGQL`,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${getToken()}`,
    },
  };
});

export const client = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});

function getToken() {
  return localStorage.getItem(LocalStorageKeys.Token);
}

export * from "./queries";
export * from "./mutations";
