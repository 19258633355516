import { useQuery } from "@apollo/client";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Gallery, Menu, Restaurant } from "@/app/types";
import {
  GET_GALLERY,
  GET_MENU,
  GET_RESTAURANT,
  GET_RESY_VALID,
} from "../../graphql";
import PageSpinner from "../common/PageSpinner";
import { AppContext } from "../../context/AppContext";
import { Severity } from "../../constants";
import NoData from "../common/NoData";

export function AppData() {
  const { t } = useTranslation();
  const {
    restaurant,
    categories,
    setCategories,
    setRestaurant,
    setResyValid,
    resyValid,
    gallery,
    setGallery,
    setIsGlobalLoading,
    setSnackBarMessageProps,
  } = useContext(AppContext);
  const {
    loading,
    data: restaurantData,
    error: restaurantDataError,
  } = useQuery<{
    restaurant: Restaurant;
  }>(GET_RESTAURANT, {
    pollInterval: 600000,
  });
  const {
    data: galleryData,
    error: galleryDataError,
    loading: galleryLoading,
  } = useQuery<{
    gallery: Gallery;
  }>(GET_GALLERY, {
    pollInterval: 60000,
  });
  const {
    data: menuData,
    error: menuDataError,
    loading: menuLoading,
  } = useQuery<{ menu: Menu }>(GET_MENU, {
    pollInterval: 60000,
  });

  const {
    data: resyValidData,
    loading: resyValidLoading,
    error: resyValidError,
  } = useQuery<{
    resyValid: boolean;
  }>(GET_RESY_VALID, {
    pollInterval: 60000,
  });

  useEffect(() => {
    setIsGlobalLoading!(
      loading || resyValidLoading || galleryLoading || menuLoading,
    );
  }, [
    loading,
    resyValidLoading,
    setIsGlobalLoading,
    galleryLoading,
    menuLoading,
  ]);

  useEffect(() => {
    if (resyValidData && !resyValidLoading && resyValid !== undefined) {
      setResyValid!(resyValidData.resyValid);
    }
  }, [
    resyValidData,
    setResyValid,
    resyValidLoading,
    setSnackBarMessageProps,
    resyValid,
  ]);
  useEffect(() => {
    if (restaurantData?.restaurant && !restaurant) {
      setRestaurant!(restaurantData.restaurant);
    }
  }, [restaurantData, restaurant, setRestaurant]);

  useEffect(() => {
    if (menuData?.menu?.categories && !categories) {
      setCategories!(menuData.menu.categories);
    }
  }, [menuData, categories, setCategories]);

  useEffect(() => {
    if (galleryData?.gallery && !gallery) {
      setGallery!(galleryData.gallery);
    }
  }, [setGallery, galleryData, gallery]);

  useEffect(() => {
    if (resyValidError) {
      setSnackBarMessageProps?.({
        message: `${t("common.error")}:Resy: ${resyValidError.message}`,
        severity: Severity.Error,
      });
    }
  }, [resyValidError, setSnackBarMessageProps, t]);

  useEffect(() => {
    if (restaurantDataError) {
      setSnackBarMessageProps?.({
        message: `${t("common.error")}:Restaurant: ${restaurantDataError.message}`,
        severity: Severity.Error,
      });
    }
  }, [restaurantDataError, setSnackBarMessageProps, t]);

  useEffect(() => {
    if (galleryDataError) {
      setSnackBarMessageProps?.({
        message: `${t("common.error")}:Gallery: ${galleryDataError.message}`,
        severity: Severity.Error,
      });
    }
  }, [galleryDataError, setSnackBarMessageProps, t]);

  useEffect(() => {
    if (menuDataError) {
      setSnackBarMessageProps?.({
        message: `${t("common.error")}:Menu: ${menuDataError.message}`,
        severity: Severity.Error,
      });
    }
  }, [menuDataError, setSnackBarMessageProps, t]);

  const lastError = restaurantDataError || galleryDataError || menuDataError;
  if (loading) {
    return <PageSpinner />;
  } else if (lastError) {
    return <NoData title={t("common.ooops")} message={lastError.message} />;
  }

  return null;
}
