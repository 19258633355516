import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
} from "@mui/material";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

const Transition = forwardRef(function Transition(props: any, ref: any) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function ImagePreviewModal({
  isOpen,
  handleClose,
  url,
}: {
  isOpen: boolean;
  handleClose: () => void;
  url: string;
}) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="lg"
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <div className="flex">
          <div className="flex-1">{t("common.preview")}</div>
          <div>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </DialogTitle>

      <DialogContent>
        <Box>
          <img
            src={url}
            alt={t("common.preview")}
            style={{ maxHeight: "60vh" }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
