import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Paper,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import { Dispatch, DragEvent, SetStateAction, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Gallery } from "../../types";

export default function FileUploader({
  existingFiles,
  uploadFiles,
  setFiles,
  files,
  isLoading,
}: {
  uploadFiles: (files: File[]) => void;
  files: File[];
  setFiles: Dispatch<SetStateAction<File[]>>;
  existingFiles?: Gallery["items"];
  isLoading?: boolean;
}) {
  const [isDragging, setIsDragging] = useState(false);
  const uploadLimit = existingFiles ? 8 - existingFiles.length : 8;
  const handleFileChange = (event: any) => {
    const selectedFiles: File[] = event.target?.files;
    if (selectedFiles && selectedFiles.length > 0) {
      const newFiles = Array.from(selectedFiles);
      setFiles((prevFiles) =>
        [...prevFiles, ...newFiles].slice(0, uploadLimit),
      );
    }
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    const droppedFiles = event.dataTransfer?.files;
    if (droppedFiles && droppedFiles.length > 0) {
      const newFiles = Array.from(droppedFiles);
      setFiles((prevFiles) =>
        [...prevFiles, ...newFiles].slice(0, uploadLimit),
      );
    }
  };

  const handleDrag = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (isLoading) return;
    setIsDragging(true);
  };

  const handleRemoveFile = (index: number) => {
    if (isLoading) return;
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  return (
    <Paper sx={{ padding: 4, marginTop: 2, textAlign: "center" }}>
      <div
        className={`${isDragging ? "border-2 border-dashed border-gray-300" : ""} rounded-md p-14`}
        onDrop={handleDrop}
        onDragEnd={() => setIsDragging(false)}
        onDragOver={handleDrag}
      >
        <>
          <Typography variant="h6">
            {t("fileUpload.uploadImageDesription")}
          </Typography>
          <Typography variant="body1" marginBottom={2}>
            {t("fileUpload.uploadImageSpecs")}
          </Typography>
          <Typography variant="body2">
            {t("fileUpload.uploadImageOrText")}
          </Typography>
          <input
            type="file"
            hidden
            disabled={isLoading}
            id="browse"
            onChange={handleFileChange}
            accept=".png,.jpg,.jpeg"
            multiple
          />
          <Button
            variant="outlined"
            disabled={isLoading}
            color="primary"
            type="button"
            sx={{ marginTop: 2, padding: 0 }}
          >
            <label htmlFor="browse" role="button" className="px-4 py-2">
              <span>{t("fileUpload.uploadButtonText")}</span>
            </label>
          </Button>
        </>
      </div>
      <Box marginTop={4}>
        <ImageList cols={4} gap={20}>
          {files.map((file, index) => (
            <ImageListItem key={file.name}>
              <img
                srcSet={URL.createObjectURL(file)}
                src={URL.createObjectURL(file)}
                alt={file.name}
                className="rounded-md"
                height={100}
                loading="lazy"
              />
              <ImageListItemBar
                sx={{
                  borderRadius: "6px 6px 0 0",
                  background:
                    "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                    "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                }}
                position="top"
                actionIcon={
                  <IconButton
                    onClick={() => handleRemoveFile(index)}
                    sx={{ color: "#fff" }}
                    aria-label={t("common.delete")}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
                actionPosition="right"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {files.length > 0 && (
        <Box textAlign="center" marginTop={2}>
          <Button
            variant="contained"
            type="button"
            disabled={isLoading}
            onClick={() => uploadFiles(files)}
          >
            {isLoading && (
              <CircularProgress
                color="inherit"
                size="1rem"
                sx={{ mx: 1 }}
                aria-label={t("common.loading")}
              />
            )}
            <span>{t("fileUpload.uploadFiles", { count: files.length })}</span>
          </Button>
        </Box>
      )}
    </Paper>
  );
}
