import { useContext } from "react";
import { UIMode } from "../../constants";
import { AppContext } from "../../context/AppContext";
import { CircularProgress } from "@mui/material";
import logoLight from "../../../assets/images/logo-light.svg";
import logoDark from "../../../assets/images/logo-dark.svg";
import { useTranslation } from "react-i18next";

export default function PageSpinner() {
  const { uiMode } = useContext(AppContext);
  const { t } = useTranslation();

  const logo = uiMode === UIMode.Dark ? logoDark : logoLight;
  return (
    <div className="m-[20%_auto] w-[100%] text-center">
      <img src={logo} alt="ACCESS" width={80} className="m-[10px_auto_40px]" />
      <CircularProgress color="inherit" aria-label={t("common.loading")} />
    </div>
  );
}
