import { Box, Button, Typography } from "@mui/material";

export default function NoData({
  title,
  message,
  action,
  actionTitle,
  topMargin = "15%",
}: {
  message?: string;
  title: string;
  actionTitle?: string;
  action?: () => void;
  topMargin?: string;
}) {
  return (
    <Box
      className={`text-center p-4 max-w-[500px] w-full`}
      sx={{ margin: `${topMargin} auto 0` }}
    >
      <Typography variant="h6">{title}</Typography>
      {message && (
        <Typography component="p" variant="body2" marginTop={0}>
          {message}
        </Typography>
      )}
      {action && (
        <p className="mt-6">
          <Button onClick={action} variant="contained" color="primary">
            {actionTitle}
          </Button>
        </p>
      )}
    </Box>
  );
}
