import { gql } from "@apollo/client";

export const DUMMY_QUERY = gql`
  {
    dummy
  }
`;

export const GET_RESTAURANT = gql`
  query Restaurant {
    restaurant {
      uuid
      name
      notes
      seatCount
      cuisine
      details {
        arrivalPolicy
        description
        generalPolicy
        cancellationPolicy
      }
      city {
        name
        location
        country
      }
      contact {
        name
        email
        phone
        website
        instagram
      }
      location {
        longitude
        latitude
      }
    }
  }
`;

export const GET_MENU = gql`
  query Menu {
    menu {
      uuid
      categories {
        uuid
        title
        items {
          uuid
          name
          order
          description
          price {
            amount
            currency
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_MANAGER = gql`
  query Manager {
    manager {
      uuid
      birthDate
      profilePicture
      username
      firstName
      lastName
      preferredName
      email
      phone
      createdAt
      updatedAt
    }
  }
`;

export const GET_RESERVATIONS = gql`
  query Reservations($from: String, $to: String) {
    reservations(search: { from: $from, to: $to }) {
      items {
        uuid
        table {
          label
          type
          location
          recommendedPartySize
          maxPartySize
          minPartySize
        }
        date {
          start
          end
        }
        status
        member {
          uuid
          membershipId
          allergies
          restrictions
          bio
          joinDate
          birthDate
          profilePicture
          username
          firstName
          lastName
          preferredName
          email
          phone
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const GET_GALLERY = gql`
  query Gallery {
    gallery {
      uuid
      primary {
        uuid
        data
        type
        alt
        createdAt
        updatedAt
      }
      items {
        uuid
        image {
          uuid
          data
          type
          alt
          createdAt
          updatedAt
        }
        order
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_RESY_VENU = gql`
  query ResyVenu {
    resyVenue {
      id
      name
    }
  }
`;

export const GET_RESY_AVAILABLE_VENUES = gql`
  query ResyAvailableVenues {
    resyAvailableVenues {
      id
      name
    }
  }
`;

export const GET_RESY_TAGS = gql`
  query ResyTags {
    resyTags {
      name
    }
  }
`;

export const GET_RESY_AVAILABLE_TAGS = gql`
  query ResyAvailableVenues {
    resyAvailableTags {
      name
    }
  }
`;

export const GET_RESY_STATUS = gql`
  query ResyStatus {
    resyStatus
  }
`;

export const GET_RESY_VALID = gql`
  query ResyValid {
    resyValid
  }
`;
