import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function Spinner() {
  const { t } = useTranslation();
  return (
    <div className="m-[10px_auto] w-[100%] text-center">
      <CircularProgress color="inherit" aria-label={t("common.loading")} />
    </div>
  );
}
