import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import Keycloak, { KeycloakConfig } from "keycloak-js";

import { DomainEnv } from "./app/config";
import {
  KEYCLOAK_CLIENT_ID,
  KEYCLOAK_REALM,
  LocalStorageKeys,
} from "./app/constants";

const domain = DomainEnv[process.env.NODE_ENV];
const initOptions: KeycloakConfig = {
  url: `https://keycloak.${domain}.accessonboard.com/`,
  realm: KEYCLOAK_REALM,
  clientId: KEYCLOAK_CLIENT_ID,
};

export const keycloak = new Keycloak(initOptions);
interface InitKeycloak {
  keycloak: Keycloak;
  auth: boolean;
}

export const logout = (client?: ApolloClient<NormalizedCacheObject>) => {
  localStorage.removeItem(LocalStorageKeys.Token);
  localStorage.removeItem(LocalStorageKeys.RefreshToken);
  client?.resetStore();
  keycloak.logout();
};

export const persistToken = (token: string, refreshToken: string) => {
  localStorage.setItem(LocalStorageKeys.Token, token);
  localStorage.setItem(LocalStorageKeys.RefreshToken, refreshToken);
};

export interface InitKeycloakParams {
  access_token: string;
  expires_in?: string;
  refresh_expires_in?: string;
  refresh_token: string;
  token_type?: string;
  "not-before-policy"?: string;
  session_state?: string;
  scope?: string;
}

let initialized = false;
export const initKeycloak = async (
  params: InitKeycloakParams,
): Promise<InitKeycloak> => {
  try {
    if (initialized) {
      keycloak.token = params.access_token;
      keycloak.refreshToken = params.refresh_token;
      return { keycloak, auth: keycloak.authenticated! };
    }

    // try {
    //   //
    // } catch (error) {
    //   console.error("Authenticated Failed", error);
    //   throw error;
    // }
    const auth = await keycloak.init({
      responseMode: "fragment",
      token: params.access_token,
      refreshToken: params.refresh_token,
      checkLoginIframe: false,
    });

    initialized = true;
    if (auth) {
      persistToken(keycloak.token!, keycloak.refreshToken!);
      setInterval(refreshToken, 1000 * 60 * 2);
      // TODO: debug why onTokenExpired is never called
      keycloak.onTokenExpired = async () => {
        console.info("token expired");
        refreshToken();
      };
    } else {
      console.error("Authenticated Failed");
      logout();
    }
    return { keycloak, auth };
  } catch (error) {
    console.error("Authenticated Failed: ", { error });
    logout();
  } finally {
    return { keycloak, auth: keycloak.authenticated! };
  }
};

const refreshToken = async () => {
  if (keycloak.authenticated) {
    const seconds = Math.round(
      (keycloak.tokenParsed?.exp ?? 0) +
        (keycloak.timeSkew ?? 0) -
        new Date().getTime() / 1000,
    );
    const refreshed = await keycloak.updateToken(300);
    console.info("attempt token refreshed");
    if (refreshed) {
      persistToken(keycloak.token!, keycloak.refreshToken!);
      console.info("token refreshed");
    } else {
      logout();
      console.error(`token not refreshed, valid for ${seconds} seconds`);
    }
  } else {
    if (!window.location.pathname.startsWith("/login")) {
      window.location.pathname = "/login";
    }
  }
};
